
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue';
import { getSmsCaptcha } from '@/api/user/login';
import { message, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import { UserOutlined, LockOutlined, MobileOutlined, MailOutlined } from '@ant-design/icons-vue';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_CUSTOME_DATA_USER,
  GET_MY_MENU_DATA_LIST,
  GET_INFO, MY_ORGINFO, LOGIN, CHANGE_CURRENT_ORG,
} from '@/store/modules/user/actions';
import { GetQueryString } from '@/utils/utils.ts';
import ls from '@/utils/local-storage';
import store from '@/store';
import { create_organization_by_user } from '@/api/sys';
import { SET_CURRENT_ORG, SET_ORGLIST } from '@/store/modules/user/mutations';

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter();
    const store = useStore();
    const showOrgReg = ref(false);
    const showWx = ref(true);
    const my_org_list:any = ref([]);
    if(process.env.NODE_ENV != 'production'){
      showWx.value = false;
    }
    const state = reactive({
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,

      time: 60,
      smsSendBtn: false,
    });

    const handleUsernameOrEmail = (rule: any, value: any) => {
      return new Promise(resolve => {
        const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (regex.test(value)) {
          state.loginType = 0;
        } else {
          state.loginType = 1;
        }
        resolve(state.loginType);
      });
    };
    const modelRef = reactive({
      rememberMe: true,
      username: '',
      password: '',
      mobile: '',
      captcha: '',
    });
    const rulesRef = reactive({
      name: [
        { required: true, message: '请输入组织名称', type: 'string' },
      ],

    });
    const { validateInfos, validate, resetFields } = useForm(modelRef, rulesRef);

    const handleTabClick = (key: string) => {
      state.customActiveKey = key;
      resetFields();
    };

    const requestFailed = (err: AxiosError) => {
      console.log('requestFailed', err?.response?.data?.errorMessage);
      state.isLoginError = true;
      // notification.error({
      //   message: '错误',
      //   description: ((err.response || {}).data || {}).errorMessage || '请求出现错误，请稍后再试',
      //   duration: 4,
      // });
    };

    const loginSuccess = () => {
      router.push({ path: '/alarm/configuration_file' });


      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: '欢迎',
          description: '欢迎回来',
        });
      }, 1000);
      state.isLoginError = false;
    };

    const getCaptcha = (e: Event) => {
      e.preventDefault();

      validate('mobile')
        .then(values => {
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = message.loading('验证码发送中..', 0);
          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500);
              notification.success({
                message: '提示',
                description: '验证码获取成功，您的验证码为：' + res.captcha,
                duration: 8,
              });
            })
            .catch(err => {
              setTimeout(hide, 1);
              clearInterval(interval);
              state.time = 60;
              state.smsSendBtn = false;
              requestFailed(err);
            });
        })
        .catch(err => {
          console.log('err', err);
        });
    };

    onMounted(()=>{
      if(window.location.href.indexOf('sessionid')>0){
        showOrgReg.value = true;
        const sessionid:string|undefined = GetQueryString('sessionid');
        ls.set('sessionid', sessionid);
        store.dispatch(`user/${GET_INFO}`);
        store.dispatch(`user/${MY_ORGINFO}`, {}).then((res:any)=>{
          if(res.org_list.data.length==0){
            state.customActiveKey = 'tab2';
          }else{
            state.customActiveKey = 'tab1';
            my_org_list.value = res.org_list.data;
          }
        })
          .catch(err => {
            requestFailed(err);
          })
          .finally(() => {
            state.loginBtn = false;
          });
      }

      if(showWx.value){
        const shcreem = window.location.href.split('//')[0];
        let href = window.location.href.replace(`${shcreem}//`,'').split('/')[0];
        if (process.env.NODE_ENV == 'development') {
          href = 'fr.8mu.com.cn';
        }
        const reg = /static_file[_A-Za-z0-9]*/;
        const namespace_arr:any = reg.exec(window.location.href);
        let namespace:any = null;
        if(namespace_arr && namespace_arr.length>0){
          namespace = namespace_arr[0].trim();
        }
        var obj = new (window as any)['WxLogin']({
          self_redirect:false,
          id: 'wxlogin',
          appid: process.env.VUE_APP_WXAPPID,
          scope: 'snsapi_login,snsapi_userinfo',
          redirect_uri: encodeURI(`${shcreem}//${href}/wx/oauth4?next_url=${btoa(`${shcreem}//${href}/${namespace}/user/login`)}`),
          state: 'formrecord',
        });
      }

    });

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames =
        state.customActiveKey === 'tab1' ? ['username', 'password'] : ['name'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          // console.log('values', values);
          create_organization_by_user(values)
            .then((res: any) => {
            store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
              org_id: Number(res.id),
            });
              store.dispatch(`user/${MY_ORGINFO}`, { org_id: res.id }).then(()=>{
                store.dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
                    org_id: Number(res.id),
                  })
                  .finally(() => {
                    loginSuccess();
                  });
              });

          })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };
    // this.loginBtn = false;
    // this.stepCaptchaVisible = false;

    const handleLoginSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames =
        state.customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          console.log('values', values);
          store
            .dispatch(`user/${LOGIN}`, {
              ...values,
              type: state.customActiveKey === 'tab1',
            })
            .then((res: any) => {
              store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                org_id: Number(res.org_list.data[0].id),
              });
              store.dispatch(`user/${GET_INFO}`);
              store
                .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
                  org_id: Number(res.org_list.data[0].id),
                })
                .then(() => {
                  store.dispatch(`user/${GET_INFO}`).then(() => {});
                })
                .finally(() => {
                  loginSuccess();
                });
            })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };

    const select_org = (res: any) => {
      store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
        org_id: Number(res.id),
      });
      store.dispatch(`user/${CHANGE_CURRENT_ORG}`, res).then(()=>{
        return store
          .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
            org_id: Number(res.id),
          });
      })
      .then(() => {
        loginSuccess();
      });
    };
    return {
      ...toRefs(state),
      showOrgReg,
      showWx,
      my_org_list,
      modelRef,
      validateInfos,

      select_org,
      handleTabClick,
      handleSubmit,
      handleLoginSubmit,
      getCaptcha,
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
  },
});
